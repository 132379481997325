import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: String }

  connect() {
    const curVal = sessionStorage.getItem(this.idValue)
    if (!curVal) {
      this.element.style.display = "block"
      this.element.classList.add('fade')
      this.element.classList.add('show')
    }
  }
  hide() {
    // slide up, overflow height
    this.element.style.overflow = "hidden"
    this.element.style.height = this.element.offsetHeight + "px"
    this.element.style.transition = "all 0.5s ease-in-out"
    this.element.style.height = "0px"
    this.element.style.paddingTop = "0px"
    this.element.style.paddingBottom = "0px"
    sessionStorage.setItem(this.idValue, "hidden")
    setTimeout(() => {
      this.element.style.display = "none"
    }, 500)
  }
}
